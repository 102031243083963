
@import'./Styles/variables.css';

body {
 
  margin: 0;
  font-family: 'Roboto', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
}

/* For WebKit-based browsers (Chrome, Safari, Edge) */
html::-webkit-scrollbar {
  width: 8px; /* Adjust width as desired */
}

html::-webkit-scrollbar-track {
  background: transparent; 
}

html::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px; /* Rounded corners for a modern look */
  border: 2px solid transparent; /* Optional: adds some padding around thumb */
  background-clip: content-box; /* Ensures rounded corners appear crisp */
}

/* Optional: Hover effect for thumb */
html::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
