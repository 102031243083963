/* ----------------------------------------------------
   src/styles/Home.css
   ---------------------------------------------------- */

/* Root variables */
:root {
  --color-primary-bg: #121212;
  --color-primary-text: #ffffff;
  --color-accent: #4CAF50;
  --font-primary: 'Segoe UI', sans-serif;
}

.home-page {
  background-color: var(--color-primary-bg);
  color: var(--color-primary-text);
  min-height: 100vh;
  overflow: hidden;
  font-family: var(--font-primary);
  position: relative;
}

/* -----------------------------------------------
 HERO SECTION (you already had this in place)
 -----------------------------------------------*/
.hero-section {
  background: linear-gradient(to right, #0b0b0b, #232323);
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 20px;
}
.hero-content {
  text-align: center;
  max-width: 700px;
  position: relative;
  z-index: 1;
}

.hero-title {
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 1.2rem;
  color: var(--color-primary-text);
}
.accent-text {
  color: var(--color-accent);
}

.hero-subtitle {
  font-size: 1.3rem;
  max-width: 600px;
  margin-bottom: 1rem;
  line-height: 1.4;
  color: var(--color-primary-text);
  opacity: 0;
  transform: translateY(15px);
  transition: opacity 0.8s ease, transform 0.8s ease;
  text-align: center;
}
.hero-subtitle.show {
  opacity: 0.9;
  transform: translateY(0);
}

.hero-quote {
  font-size: 1.1rem;
  font-style: italic;
  color: #cccccc;
  max-width: 700px;
  margin: 0 auto;
  margin-top: 0.5rem;
  text-align: center;
  opacity: 0;
  transform: translateY(15px);
  transition: opacity 0.8s ease, transform 0.8s ease;
}
.hero-quote.show {
  opacity: 1;
  transform: translateY(0);
}

/* Particles container fade-in */
.particles-container {
  position: absolute;
  inset: 0;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
}
.particles-container.show {
  opacity: 1;
  pointer-events: auto;
}

/* -----------------------------------------------
 CATEGORY TITLE (Featured Projects, About Me)
 -----------------------------------------------*/
.category-title {
  font-size: 2rem;
  font-weight: bold;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
  color: var(--color-primary-text);
  padding-left: 10px;
  transition: color 0.3s ease;
}
.category-title:hover {
  color: var(--color-accent);
  animation: glitch 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.category-title::after {
  content: '';
  display: block;
  width: 100px;
  height: 5px;
  background-color: var(--color-accent);
  margin: 8px 0 0;
  border-radius: 2px;
  animation: pulse 1.5s infinite;
}

/* Glitch Keyframe */
@keyframes glitch {
  0%   { transform: translate(0); opacity: 1; }
  20%  { transform: translate(-2px, 2px); opacity: 0.8; }
  40%  { transform: translate(2px, -2px); opacity: 1; }
  60%  { transform: translate(-1px, 1px); opacity: 0.9; }
  80%  { transform: translate(1px, -1px); opacity: 1; }
  100% { transform: translate(0); opacity: 1; }
}
/* Pulse Keyframe */
@keyframes pulse {
  0%, 100% { opacity: 1; transform: scaleX(1); }
  50% { opacity: 0.7; transform: scaleX(0.9); }
}

/* Buttons */
.view-all-button {
  margin-top: 20px;
  background-color: #1DB954;
  color: #fff;
  transition: background-color 0.2s ease;
}
.view-all-button:hover {
  background-color: #00ffff;
}

/* ABOUT SECTION */
.about-section {
  margin-top: 60px;
}
.about-text {
  font-size: 1.2rem;
  max-width: 800px;
  line-height: 1.6;
  margin-top: 10px;
  color: var(--color-primary-text);
}

/* New: Skills Grid */
.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.skill-box {
  background-color: #1e1e1e; /* slightly lighter than background for contrast */
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.2s ease;
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
}
.skill-box:hover {
  transform: scale(1.03);
  box-shadow: 0 0 12px rgba(0,255,127,0.3);
}
.skill-icon {
  font-size: 2.5rem;
  color: var(--color-accent);
  margin-bottom: 8px;
}
.skill-name {
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #eee;
}

/* Fun Fact text */
.fun-fact {
  margin-top: 20px;
  font-style: italic;
  color: #cccccc;
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* -----------------------------------------------
 RESPONSIVE TWEAKS
 -----------------------------------------------*/
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }
  .hero-subtitle {
    font-size: 1.1rem;
  }
  .skills-grid {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}
@media (max-width: 500px) {
  .hero-title {
    font-size: 2rem;
  }
  .hero-subtitle {
    font-size: 1rem;
  }
  .category-title {
    font-size: 1.4rem;
  }
  .category-title::after {
    width: 80px;
  }
}


/* Blinking green cursor for the typewriter effect */
.console-cursor {
  display: inline-block;
  width: 3px;
  height: 1.2em;
  background-color: var(--color-accent);
  vertical-align: bottom;
  margin-left: 4px;
  animation: blink 0.7s steps(1) infinite;
}
@keyframes blink {
  0%, 100% { opacity: 1; }
  50%      { opacity: 0; }
}
