@import'./Styles/variables.css';

:root {

  --background-color: #404241;
  --primary-text-color: #FFFFFF;
  --secondary-text-color: #B0B0B0;
  --accent-color: #59e63d;
  --hover-color: #1ED760;

}

body {
  background-color: var(--background-color);
  color: var(--primary-text-color);
  font-family: 'Segoe UI', sans-serif;
  margin: 0;
  padding: 0;
}

.content-wrapper {
  padding-top: 68px; /* Adjust based on navbar height */
}