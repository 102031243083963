/* src/styles/MediaGallery.css */

/* --------------------------
   THEME VARIABLES (adjust these)
--------------------------- */
:root {
  --overlay-bg: rgba(0, 0, 0, 0.9); /* black overlay */
  --color-accent: #4CAF50;          /* accent color (e.g., nav buttons hover) */
  --color-text: #ffffff;            /* text color */
  --color-muted-text: #cccccc;      /* muted/hover text color */

  --font-primary: 'Segoe UI', sans-serif;
  --font-secondary: 'Segoe UI', sans-serif;

  --font-size-title: clamp(1.8rem, 2vw, 2.5rem);
  --font-size-subtitle: clamp(1.5rem, 1.8vw, 2rem);
  --font-size-body: clamp(0.9rem, 1.2vw, 1rem);
}

/* --------------------------
   GALLERY THUMBNAILS
--------------------------- */
.media-gallery {
  padding: 10px 0px;
  font-family: var(--font-primary);
}

.media-thumbnails {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 10px;
  padding: 0 1rem;
  box-sizing: border-box;
}

.thumbnail-wrapper {
  overflow: hidden;
  border-radius: 8px;
  background-color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px; /* Desktop default */
  cursor: pointer;
}

.media-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s ease;
}

.media-thumbnail:hover {
  transform: scale(1.05);
}

/* --------------------------
   FULLSCREEN MODAL (Overlay)
--------------------------- */
.media-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-bg);
  z-index: 9999;

  /* Center content horizontally & vertically */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Allow vertical scroll if content is tall */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* The container that holds the fullscreen image + nav/close buttons */
.media-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  margin: auto; /* ensures it’s centered */
  border-radius: 8px;
  box-sizing: border-box;
  /* optional subtle background behind image:
     background-color: rgba(0, 0, 0, 0.2);
  */
}

/* The fullscreen image itself */
.media-fullscreen-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

/* --------------------------
   NAV BUTTONS & CLOSE BUTTON
--------------------------- */
.media-nav-button,
.media-close-button {
  position: absolute;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  border: none;
  cursor: pointer;
  font-size: 2rem;
  border-radius: 50%;
  padding: 0.4em 0.6em;
  z-index: 1;
  transition: background-color 0.2s ease, color 0.2s ease; /* But NOT transform here */
  transform-origin: center; /* Ensures the scale transforms from the center */
}

/* Hover states */
.media-nav-button:hover,
.media-close-button:hover {
  color: var(--color-accent);
  background-color: rgba(0, 0, 0, 0.7);
}

/* Remove the "push down"/scale effect on click */
.media-nav-button:active,
.media-close-button:active {
  transform: none !important;
}

/* Close button (X) - top-right */
.media-close-button {
  top: 15px;
  right: 15px;
  font-size: 2rem;
}

/* Prev/Next Buttons */
.media-nav-button.prev,
.media-nav-button.next {
  top: 50%;
}
.media-nav-button:active,
.media-close-button:active {
  animation: navButtonPush 0.25s ease; /* or 0.2s, adjust as you like */
}
.media-nav-button.prev {
  left: 20px;
}
.media-nav-button.next {
  right: 20px;
}

@keyframes navButtonPush {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9); /* push in */
  }
  70% {
    transform: scale(1.05); /* small overshoot */
  }
  100% {
    transform: scale(1); /* back to normal */
  }
}

/* --------------------------
   RESPONSIVE (Mobile tweaks)
--------------------------- */
@media (max-width: 600px) {
  .media-thumbnails {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 8px;
  }
  .thumbnail-wrapper {
    height: 70px;
  }

  .media-close-button {
    top: 10px;
    right: 10px;
    font-size: 1.8rem;
  }
  .media-nav-button {
    font-size: 1.8rem;
    padding: 0.3em 0.5em;
  }
}
