/* Category container */
.category {
  position: relative;
  padding: px 4%; /* Add padding to sides */
  overflow: visible; /* Allow cards to overflow on hover */
}

/* Grid container */
.project-grid-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px 0; /* Add padding to contain hover effects */
  overflow: visible; /* Allow hover animations to overflow */
}

/* Project grid */
.project-grid {
  display: flex;
  overflow-x: auto;
  overflow-y: visible; /* Allow hover effects to overflow vertically */
  scroll-behavior: smooth;
  padding: 20px 40px; /* Add padding to show first card fully */
  gap: 8px;
  margin: -20px 0; /* Offset padding to maintain spacing */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Scroll Buttons Styling */
.scroll-button {
  position: absolute;
  top: 50%; /* Center vertically */
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black */
  border: 2px solid transparent; /* Default border */
  color: #ffffff; /* White arrow */
  width: 45px; /* Button size */
  height: 45px;
  border-radius: 50%; /* Circular shape */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: transform 0.3s ease, border-color 0.3s ease, background-color 0.3s ease; /* Smooth transitions */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); /* Subtle shadow */
}

/* Hover Effect - Animate Down with Border Pulse */
.scroll-button:hover {
  background-color: rgba(6, 15, 6, 0.384); /* Green for hover visibility */
  border-color: rgba(0, 255, 0, 1); /* Green glowing border */
  animation: border-pulse 1.5s infinite; /* Pulse effect */
}

/* Smooth Reset After Hover Ends */
.scroll-button:not(:hover) {
  background-color: rgba(0, 0, 0, 0.4); /* Reset background color */
  border-color: transparent; /* Reset border */
  animation: none; /* Stop pulsing */
}

/* SVG Icon for Arrows */
.scroll-button svg {
  width: 20px;
  height: 20px;
  fill: currentColor; /* Matches button text color */
}

/* Pulse Animation */
@keyframes border-pulse {
  0%, 100% {
    border-color: rgba(0, 255, 0, 0.6); /* Dimmer green */
    box-shadow: 0 0 10px rgba(0, 255, 0, 0.4); /* Subtle glow */
  }
  50% {
    border-color: rgba(0, 255, 0, 1); /* Bright green */
    box-shadow: 0 0 15px rgba(0, 255, 0, 0.7); /* Stronger glow */
  }
}


/* Left Button */
.scroll-button.left {
  left: 15px; /* Slightly inside the container */
}

/* Right Button */
.scroll-button.right {
  right: 15px; /* Slightly inside the container */
}


/* Media queries */
@media (max-width: 768px) {
  .scroll-button {
    display: flex; /* Ensure buttons are visible on mobile */
    width: 35px; /* Adjust size for smaller screens */
    height: 35px;
  }

  .scroll-button.left {
    left: 3px; /* Adjust spacing for smaller screens */
  }

  .scroll-button.right {
    right: 3px; /* Adjust spacing for smaller screens */
  }

  .scroll-button svg {
    width: 16px; /* Slightly smaller icon */
    height: 16px;
  }
}