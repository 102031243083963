:root {
  --background-color: #121212;
  --primary-text-color: #FFFFFF;
  --secondary-text-color: #B0B0B0;
  --accent-color: #1DB954;
  --hover-color: #1ED760;
}

.MuiCard-root.custom-card {
  background-color: var(--background-color); /* Dark background */
  color: var(--primary-text-color); /* Primary text color */
  border: 3px solid var(--accent-color); /* Accent color border */
  box-shadow: 0 0 5px var(--accent-color), 0 0 10px var(--accent-color), 0 0 15px var(--accent-color), 0 0 20px var(--accent-color); /* Neon glow effect */
  transition: all 0.2s ease-in-out;
}

.MuiCard-root.custom-card:hover {
  box-shadow: 0 0 5px var(--hover-color), 0 0 10px var(--hover-color), 0 0 15px var(--hover-color), 0 0 20px var(--hover-color); /* Hover glow effect */
  border-color: var(--hover-color); /* Hover border color */
}

.custom-card-title {
  color: var(--primary-text-color); /* Title text color */
  text-shadow: 0 0 5px var(--accent-color), 0 0 10px var(--accent-color); /* Text glow for titles */
}

.custom-card-body {
  color: var(--secondary-text-color); /* Body text color */
}

.custom-card-actions {
  border-top: 2px solid var(--accent-color); /* Accent color border for the action area */
}

.custom-card-button {
  color: var(--accent-color); /* Button text color */
  border: 2px solid var(--accent-color); /* Button border color */
  transition: all 0.2s ease-in-out;
}

.custom-card-button:hover {
  background-color: var(--accent-color); /* Button background color on hover */
  color: var(--background-color); /* Button text color on hover */
  border-color: var(--hover-color); /* Button border color on hover */
  box-shadow: 0 0 5px var(--accent-color), 0 0 10px var(--accent-color), 0 0 15px var(--accent-color), 0 0 20px var(--accent-color); /* Maintain the glow on hover */
}

.post-thumbnail {
  width: 100%; /* Adjust the width as needed */
  height: auto; /* Adjust the height as needed */
}

.custom-card {
  width: 100%; /* Adjust the width as needed */
  height: auto; /* Adjust the height as needed */
  /* Add your other styles here */
}