/* src/styles/variables.css */

:root {
    /* Primary Colors */
    --color-primary-bg: #1e1e1e;
    --color-primary-text: #ffffff;
    --color-accent: #1db954; /* Your preferred green */
    --color-accent-hover: #17a44c;
    --color-techColor: #00ff99;

  
    /* Secondary Colors */
    --color-muted-text: #cccccc;
  
    /* Additional Colors */
    --color-light-gray: #f0f0f0;
    --color-medium-gray: #888888;
    --color-dark-gray: #333333;
  
    /* Typography */
    --font-primary: 'Roboto', sans-serif;
    --font-secondary: 'Montserrat', sans-serif;
    --font-size-title: 2.5em;
    --font-size-subtitle: 1.8em;
    --font-size-body: 1.1em;
    --font-size-small: 0.95em;
  }
  