/* src/styles/Modal.css */

/* Root Variables */
:root {
  --font-size-title: clamp(1.8rem, 2vw, 2.5rem);
  --font-size-subtitle: clamp(1.5rem, 1.8vw, 2rem);
  --font-size-body: clamp(0.9rem, 1.2vw, 1rem);
  --font-size-small: clamp(0.8rem, 1vw, 0.95rem);
}

/* Always show scrollbar on desktop to avoid layout shift */
html {
  overflow-y: scroll;
}

/* Modal Overlay */
.project-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Match viewport width exactly */
  height: 100vh; /* Match viewport height exactly */
  background-color: rgba(30, 30, 30, 0.85);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* No scrolling on overlay */
  perspective: 1000px;
}

/* Modal Content */
.project-modal-content {
  position: relative;
  background-color: var(--color-primary-bg);
  color: var(--color-primary-text);
  border-radius: 12px;
  max-width: 900px; /* Same as your original desktop look */
  width: 100%;
  max-height: 90vh;
  overflow: auto;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  transform-style: preserve-3d;
  backface-visibility: hidden;

  /* Scrollbar styling */
  scrollbar-gutter: stable;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
}

.project-modal-content::-webkit-scrollbar {
  width: 8px;
}

.project-modal-content::-webkit-scrollbar-track {
  background: transparent;
}

.project-modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

/* Close Button */
.project-modal-close {
  position: absolute;
  top: 15px;
  right: 20px;
  color: var(--color-primary-text);
  font-size: 1.8rem;
  font-weight: bold;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
}

.project-modal-close:hover {
  color: var(--color-accent-hover);
  transform: rotate(180deg) scale(1.2);
}

/* Modal Details */
.modal-details {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* Modal Title */
.modal-title {
  font-family: var(--font-secondary);
  font-size: var(--font-size-title);
  margin-bottom: 10px;
  color: var(--color-primary-text);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  white-space: normal;
  overflow-wrap: break-word;
}

.modal-title::after {
  content: '';
  display: block;
  width: 80px;
  height: 5px;
  background-color: var(--color-accent);
  margin: 10px auto 0;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

/* Gallery Title */
.gallery-title {
  text-align: left;
  color: #4CAF50;
  font-family: var(--font-secondary);
  font-size: var(--font-size-subtitle);
  white-space: normal;
  overflow-wrap: break-word;
  margin-bottom: 6px; /* Adjust as needed */
}

/* Descriptions and Other Text */
.modal-description,
.modal-role,
.modal-contributions,
.modal-achievements {
  margin: 8px 0;
  font-size: var(--font-size-body);
  line-height: 1.4;
  color: var(--color-muted-text);
  flex-shrink: 1;
  white-space: normal;
  overflow-wrap: break-word;
}

.modal-role strong,
.modal-contributions strong,
.modal-achievements strong {
  color: var(--color-accent);
}

/* Technologies and Tags */
.modal-technologies,
.modal-tags {
  margin: 5px 0;
}

.modal-technologies h3,
.modal-tags h3 {
  font-family: var(--font-secondary);
  font-size: var(--font-size-subtitle);
  margin-bottom: 12px;
  margin-top: 8px;
  color: var(--color-accent);
  white-space: normal;
  overflow-wrap: break-word;
}

.technologies-list,
.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.project-tech,
.project-tag {
  background-color: var(--color-dark-gray);
  color: var(--color-primary-text);
  padding: 5px 10px;
  border-radius: 25px;
  font-size: var(--font-size-small);
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.project-tech:hover,
.project-tag:hover {
  background-color: var(--color-accent);
  color: #fff;
}

.project-tech svg {
  margin-right: 5px;
  color: #fff;
}

.project-tech {
  cursor: pointer;
}

/* Visit Project Button */
.view-project-button {
  display: inline-block;
  margin-top: auto;
  padding: 10px 20px;
  background-color: var(--color-accent);
  color: var(--color-primary-text);
  text-decoration: none;
  border-radius: 25px;
  font-size: var(--font-size-body);
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  align-self: flex-start;
  margin-top: 12px;
  transform-origin: center;
}

.view-project-button:hover {
  background-color: var(--color-accent-hover);
  transform: translateY(-2px);
}

/* --------------------- */
/*      RESPONSIVE       */
/* --------------------- */

/* For screens up to 800px wide */
@media (max-width: 800px) {
  .project-modal-content {
    max-width: 95vw; /* Narrower on mobile */
    padding: 15px;
  }

  .modal-title {
    font-size: calc(var(--font-size-title) - 0.2rem);
  }

  .modal-description,
  .modal-role,
  .modal-contributions,
  .modal-achievements {
    font-size: var(--font-size-body);
  }

  .modal-technologies h3,
  .modal-tags h3 {
    font-size: calc(var(--font-size-subtitle) - 0.2rem);
  }

  .view-project-button {
    padding: 8px 16px;
    font-size: 0.95em;
  }

  /* Move & slightly shrink the close button so it doesn't overlap nav bar */
  .project-modal-close {
    top: 60px;      /* Increase top offset to clear the nav bar */
    right: 20px;
    font-size: 1.4rem; /* Slightly smaller text so it doesn't overlap or appear too large */
  }
}

/* For screens with height under 700px */
@media (max-height: 700px) {
  .project-modal-content {
    padding: 10px;
  }

  .modal-title {
    font-size: calc(var(--font-size-title) - 0.4rem);
  }

  .modal-description,
  .modal-role,
  .modal-contributions,
  .modal-achievements {
    font-size: calc(var(--font-size-body) - 0.1rem);
  }

  .view-project-button {
    padding: 6px 12px;
    font-size: 0.9em;
  }
}

/* For screens below 500px wide or 600px tall */
@media (max-width: 500px), (max-height: 600px) {
  .modal-title {
    font-size: 1.4rem;
  }

  .gallery-title {
    font-size: 1.2rem;
  }

  .project-modal-close {
    font-size: 1.2rem;
  }

  .view-project-button {
    font-size: 0.85em;
  }
}
