.project-card {
  flex: 0 0 auto;
  width: 100%;
  max-width: 300px;
  aspect-ratio: 16 / 9;
  margin: 0 8px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  background-color: #141414;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
  cursor: pointer;
}

.project-card::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 12px;
  background: linear-gradient(45deg, #00ffff, #ff00ff, #00ffff);
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: -1;
}

/* Desktop hover effects */
@media (hover: hover) {
  .project-card:hover::before {
    opacity: 1;
    transform: scale(1.05);
  }

  .project-card:hover {
    transform: translateY(-12px) scale(1.05);
    z-index: 5;
    box-shadow:
      0 25px 35px rgba(0, 0, 0, 0.9),
      0 0 5px rgba(0, 255, 170, 0.8),
      0 0 8px rgba(0, 255, 170, 0.6),
      0 0 12px rgba(0, 255, 170, 0.4);
    border-radius: 12px; 
  }

  .project-card:hover .project-image {
    transform: scale(1.15);
  }

  .project-card:hover .project-overlay {
    transform: translateY(0);
    opacity: 1;
    height: 70%;
  }
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.project-overlay {
  font-family: 'Segoe UI', sans-serif;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* For desktop, overlay starts at bottom. For mobile, visible class sets it fully shown. */
  height: 70%; 
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.6));
  color: #ffffff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.6s ease, opacity 0.6s ease;
  /* No scrolling, we ensure content fits */
  overflow: hidden;
}

.project-overlay.visible {
  /* On mobile after first tap: show overlay fully. 
     Let's use a bigger height so that title + up to 6 icons fit nicely. */
  transform: translateY(0) !important;
  opacity: 1 !important;
  height: 85%; /* Slightly taller to fit icons and text comfortably */
}

.project-title {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
  color: #f1f1f1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);

  /* Keep the title on a single line and truncate if too long */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  max-width: 100%;
}

.project-company {
  font-size: 0.9em;
  margin-top: 0;
  color: #cccccc;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  white-space: normal;
  overflow-wrap: break-word;
}

.project-technologies {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 icons per row */
  gap: 6px;
  /* Without scroll and with ~2 rows of icons max (6 icons total) */
}

.project-tech {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.85em;
  color: #ffffff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  transition: background 0.3s ease, transform 0.3s ease, color 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.project-tech svg {
  margin-right: 3px;
  width: 14px;
  height: 14px;
  transition: fill 0.3s ease;
}

.project-tech:hover {
  background: rgba(255, 255, 255, 0.4);
  transform: scale(1.1);
  color: #00ff80;
}

.project-tech:hover svg {
  fill: #ffffff;
}

/* Responsive Design */
@media (max-width: 800px) {
  .project-grid {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .project-card {
    width: 70%;
    max-width: none;
    aspect-ratio: 16 / 9;
    margin: 0 auto;
  }
}
