/* src/styles/Portfolio.css */

.portfolio {
  position: relative;
  padding: 20px 10px;
  background-color: var(--color-primary-bg);
  color: var(--color-primary-text);
  font-family: var(--font-primary);
  overflow: hidden; /* Ensure particles don't overflow */
  z-index: 1;
  min-height: 100vh; /* Forces full-screen height */
  height: 100%; /* Ensures it adapts to dynamic height changes */
}

.portfolio-particles-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0; /* Below the main content */
  pointer-events: none; /* Prevent interactions with particles */
}

#tsparticles canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Ensure it doesn't interfere with other elements */
}


/* Rest of your CSS remains the same */

/* Category Styles */
.category {
  margin-bottom: 40px;
  position: relative;
}

.category-title {
  font-size: 1.8em;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 10px;
  padding-left: 10px;
  position: relative;
  text-transform: capitalize;
  font-family: 'Segoe UI', sans-serif;
  letter-spacing: 0.5px;
  transition: color 0.3s ease;
}

.category-title:hover {
  color: var(--color-accent);
  animation: glitch 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes glitch {
  0% {
    transform: translate(0);
    opacity: 1;
  }
  20% {
    transform: translate(-2px, 2px);
    opacity: 0.8;
  }
  40% {
    transform: translate(2px, -2px);
    opacity: 1;
  }
  60% {
    transform: translate(-1px, 1px);
    opacity: 0.9;
  }
  80% {
    transform: translate(1px, -1px);
    opacity: 1;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

.category-title::after {
  content: '';
  display: block;
  width: 100px;
  height: 5px;
  background-color: var(--color-accent);
  margin: 8px 0 0;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  animation: pulse 1.5s infinite;
  transition: width 0.4s ease;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
  50% {
    opacity: 0.7;
    transform: scaleX(0.9);
  }
}

/* Project Grid Container */
.project-grid-container {
  position: relative;
  display: flex;
  align-items: left;
}

/* Project Grid */
.project-grid {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  justify-content: flex-start;
  padding: 0 40px;
  padding-bottom: 10px;
  scroll-behavior: smooth;
  overflow-x: auto;
}

.project-grid::-webkit-scrollbar {
  display: none;
}

.project-grid {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Responsive Design */
@media (max-width: 800px) {
  .project-grid {
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Ensure enough spacing */
  }

  .project-card {
    width: 70%;
    max-width: none;
    margin: 0 auto; /* Center each card */
  }

  .category-title {
    font-size: 2em;
    margin-bottom: 8px;
  }

  .category-title::after {
    width: 60px;
    height: 4px;
  }

  .scroll-button {
    display: none;
  }
}
