/* ----------------------------------------------------
   src/styles/Resume.css
   ---------------------------------------------------- */

/* Root variables */
:root {
    --color-primary-bg: #121212;
    --color-primary-text: #ffffff;
    --color-accent: #4CAF50;
    --font-primary: 'Segoe UI', sans-serif;
  }
  
  .resume-page {
    background-color: var(--color-primary-bg);
    color: var(--color-primary-text);
    min-height: 100vh;
    overflow: hidden;
    font-family: var(--font-primary);
    text-align: center;
    padding: 40px;
  }
  
  .resume-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
    color: var(--color-primary-text);
    position: relative;
  }
  
  .resume-subtitle {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #cccccc;
  }
  
  .resume-preview-container {
    margin: 20px 0;
  }
  
  .resume-preview {
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 600px;
    border-radius: 12px;
    box-shadow: 0 10px 25px rgba(50, 255, 126, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .resume-preview:hover {
    transform: scale(1.02);
    box-shadow: 0 10px 35px rgba(50, 255, 126, 0.5);
  }
  
  .resume-buttons {
    margin-top: 20px;
  }
  
  /* Add hover effect to buttons */
  .resume-button:hover {
    transform: scale(1.05);
  }
  