/* src/components/NavBar.css */

@import url('https://fonts.googleapis.com/css2?family=Consolas&display=swap'); /* Monospaced font */

.navbar {
    font-family: 'Consolas', monospace; /* Monospaced font for consistency */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%; /* Full width for desktop */
    height: 68px;
    background: rgba(18, 18, 18, 0.98);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem; /* Increased padding for better spacing */
    z-index: 1000;
    animation: slideDown 0.5s ease-out;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.navbar-container {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.navbar-brand {
    margin-right: auto; /* Ensures brand stays on the left */
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #4CAF50, 0 0 20px #4CAF50, 0 0 30px #4CAF50, 0 0 40px #4CAF50;
  }
  to {
    text-shadow: 0 0 20px #4CAF50, 0 0 30px #4CAF50, 0 0 40px #4CAF50, 0 0 50px #4CAF50;
  }
}

.logo-text {
  font-size: 1.5rem;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 0.5px;
  animation: glow 2s infinite alternate;
}

.logo-accent {
  color: #4CAF50;
  font-size: 1rem;
  font-weight: 400;
}

.navbar-nav {
    display: flex;
    list-style: none;
    gap: 1.5rem;
    margin: 0 auto; /* Centers the navigation within the container */
    padding: 0;
    flex-wrap: wrap;
    transition: transform 0.3s ease;
}

.nav-item {
  position: relative;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  font-weight: 500; /* Consistent font weight */
  transition: color 0.2s ease, background-color 0.2s ease; /* Smooth color transitions */
  position: relative;
  padding-bottom: 6px; /* Increased padding to accommodate the progress bar */
}

.nav-link:hover,
.nav-link.active {
  color: #ffffff;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0; /* Align with padding */
  left: 0;
  width: 100%;
  height: 2px;
  background: #4CAF50;
  transform: scaleX(0);
  transition: transform 0.2s ease;
}

.nav-link:hover::after,
.nav-link.active::after {
  transform: scaleX(1);
}

.nav-link.active::after {
  animation: heartbeat 1s infinite;
}

/* Binary Transformation Styles */
.nav-link.binary {
  color: #4CAF50; /* Binary text color */
  /* No font-family change to prevent jumps */
  /* Optionally, you can change font-weight or add other styles */
}

.nav-link.binary::after {
  transform: scaleX(1); /* Ensure underline is visible during binary */
  animation: none; /* Disable heartbeat during binary transformation */
}

/* Progress Bar Styles */
.progress-bar {
  position: absolute;
  bottom: -3px; /* Position it just below the underline */
  left: 0;
  height: 3px;
  background: linear-gradient(to right, #4CAF50, #81C784); /* Gradient effect */
  transition: width 0.1s linear;
  width: 0%; /* Initial width */
  z-index: 10; /* Ensure it appears above other elements */
}

/* Heartbeat Keyframes */
@keyframes heartbeat {
    0% {
      transform: scaleX(1);
      opacity: 1;
    }
    25% {
      transform: scaleX(1.2);
      opacity: 0.8;
    }
    50% {
      transform: scaleX(1);
      opacity: 1;
    }
    75% {
      transform: scaleX(1.2);
      opacity: 0.8;
    }
    100% {
      transform: scaleX(1);
      opacity: 1;
    }
}

/* Reduced Motion for Accessibility */
@media (prefers-reduced-motion: reduce) {
  .nav-link::after {
    transition: none;
    animation: none;
  }

  .nav-link.binary {
    animation: none;
  }

  .logo-text {
    animation: none;
  }

  .progress-bar {
    transition: none;
    display: none; /* Hide progress bar */
  }
}

.menu-toggle {
  display: none;
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    background: none;
    border: none;
    padding: 2rem;
    cursor: pointer;
  }

  .menu-toggle .bar {
    display: block;
    width: 24px;
    height: 2px;
    margin: 6px 0;
    background: #fff;
    transition: 0.3s ease;
  }

  .menu-toggle.open .bar:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
  }

  .menu-toggle.open .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle.open .bar:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
  }

  .navbar-nav {
    position: absolute;
    top: 68px;
    right: 0;
    flex-direction: column;
    background: rgba(18, 18, 18, 0.98);
    padding: 1rem 2rem;
    gap: 1rem;
    transform: translateX(100%);
    width: 200px;
  }

  .navbar-nav.show {
    transform: translateX(0);
  }

  .nav-text {
    font-size: 1rem;
  }

  .navbar-icons {
    display: none;
  }
}
